import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeroImage from "../components/HeroImage"
import ContactForm from "../components/ContactForm"
import InfoBox from "../components/InfoBox"
import PostPreview from "../components/PostPreview"
import {graphql} from 'gatsby'

class IndexPage extends React.Component {
    render() {
        const items = [];
        const posts = this.props.data.allMarkdownRemark.edges;
        posts.forEach((post) => {
            items.push(<PostPreview data={post} key={post.node.frontmatter.path}/>);
        });

        return (
            <Layout>
                <SEO title="Projektziele mit Strategie erreichen" description="Informationen für Product Owner, Produktmanager und Projektleiter zur holistischen Entwicklung"/>
                <HeroImage
                    heroImageTitle={<React.Fragment>Think. Act.<br/> Be Holistic.</React.Fragment>}
                    heroImageSubtitle="Projektziele mit Strategie und klarem Blick besser und schneller erreichen - Holistische Entwicklung"
                />
                <InfoBox/>
                <div className="IndexPosts-wrapper" id='artikel'>
                    <h2 className="Index-title">Letzte Artikel ...</h2>
                    <div className="IndexPosts-container">
                        {items}
                    </div>
                </div>
                <ContactForm/>
            </Layout>
        );
    }
}

export default IndexPage;

export const pageQuery = graphql`
query IndexQuery {
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, limit: 3) {
    edges {
      node {
        timeToRead
        frontmatter {
          title
          description
          image {
            childImageSharp {
                resize(quality: 100, width: 800) {
                    src
                }
            }
          }
          path
          category
          date
        }
      }
    }
  }
}
`;
