import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

const HeroImage = props => (
    <div className="HeroImage-container">
        <div className="HeroImage-text-container">
            <h1 className="HeroImage-title">
                {props.heroImageTitle}
            </h1>
            <p className="HeroImage-subtitle">{props.heroImageSubtitle}<br/>
                <AnchorLink offset='110' href='#artikel' className="button orange">
                    Artikel ansehen</AnchorLink></p>
        </div>
    </div>
);

export default HeroImage